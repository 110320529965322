body {
  margin: 0;
  font-family: 'Manrope', 'sans-serif';
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-manrope {
  font-family: 'Manrope', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-weight: 700;
}

h2 {
  font-weight: 700;
}

h3 {
  font-weight: 500;
}

p {
  font-weight: 400;
}

/* Custom classes */

.application-background {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  overflow: auto;
  padding: 5rem 8rem;
  @apply bg-gray-background;
}

.application-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 3rem 3rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  height: 100%;
  overflow: auto;
  background-color: #ffffff;
  max-width: 750px;
}

.auth-container {
  max-width: 400px;
}

.math-line {
  display: flex;
  justify-content: space-between;
  text-align: left;
}

#loan-terms-modal-title {
  margin-bottom: 1rem;
}

.down-payment-loan-terms {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.step-descriptor {
  font-size: 1.125rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  font-family: 'Manrope', serif;
}

.card-title {
  font-family: 'Manrope', serif;
  @apply pb-8;
  @apply text-2xl;
}

.approval-image {
  @apply w-80;
  @apply h-80;
  @apply mx-auto;
}

.inline-form-group {
  display: flex;
  flex-direction: row;
  @apply space-x-4;
}

.identity-action-box {
  display: flex;
  flex-direction: row;
  
  align-items: center;

  @apply bg-gray-background;

  padding: 2rem;

  @apply w-full;
  @apply space-x-12;
}

.identity-action-detail {
  display: flex;
  flex-direction: row;

  @apply space-x-12;
}

/* Smaller laptops and tablets in landscape */
@media (max-width: 1024px) {
  .application-background {
    padding: 4rem 5rem;
  }

  .identity-action-box {
    @apply space-x-8;
  }

  .identity-action-detail {
    @apply space-x-8;
  }
}

/* Tablets */
@media (max-width: 768px) {
  .application-background {
    padding: 3rem;
  }

  .application-container {
    padding: 3rem 2rem;
  }

  .approval-image {
    @apply w-80;
    @apply h-80;
  }

  .inline-form-group {
    flex-direction: column;
    @apply space-y-4;
    @apply space-x-0;
  }
}

/* Not a standard breakpoint, so don't use
   for further CSS unless necessary. */
@media (max-width: 615px) {
  .identity-action-detail {
    flex-direction: column;
    @apply space-x-0;
    @apply space-y-2;
  }
}

/* Larger smartphones */
@media (max-width: 480px) {
  .application-background {
    padding: 2rem;
  }

  .application-container {
    padding: 2rem;
  }

  .approval-image {
    @apply w-64;
    @apply h-64;
  }

  .inline-form-group {
    flex-direction: column;
    @apply space-y-4;
  }

  .identity-action-box {
    flex-direction: column;
    align-items: stretch;
    
    @apply space-x-0;
    @apply space-y-4;
  }
}

/* Not a standard breakpoint, so don't use
   for further CSS unless necessary. */
@media (max-width: 400px) {
  #loan-terms-modal-title {
    margin-bottom: 1.5rem;
  }

  .down-payment-loan-terms {
    flex-direction: column;
    margin-bottom: 1rem;
  }

  .down-payment-loan-terms span {
    display: flex;
  }

  .down-payment-loan-terms span:first-child {
    align-items: flex-start;
    margin-bottom: 0.25rem;
  }

  .down-payment-loan-terms span:nth-child(2) {
    justify-content: flex-end;
  }
}

/* Smaller smartphones */
@media (max-width: 320px) {
  .application-background {
    padding: 1rem;
  }

  .approval-image {
    @apply w-48;
    @apply h-48;
  }

  .inline-form-group {
    flex-direction: column;
    @apply space-y-4;
  }
}

